import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import AdminPage from "./Pages/AdminPage";
import Header from "./components/Header";
import PolicyPage from "./Pages/PolicyPage/PolicyPage";
import Footer from "./components/Footer/Footer";
import ContactUs from "./Pages/ContactUs/ContactUs";
import FAQ from "./Pages/FAQ/Faq";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/faq" element={<FAQ />} />

            <Route path="/admin" element={<AdminPage />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
