import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Logo from "../../static/logo_1.png";
import "./styles.css";

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ background: "#fff" }}>
      <Toolbar>
        <Typography variant="h6">
          <img
            src={Logo}
            alt="NoSelfiee Logo"
            style={{ height: "50px", marginRight: "10px" }}
          />
          {/* Company Name */}
        </Typography>
        <Tabs
          value={false}
          aria-label="basic tabs example"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Home" sx={{ textTransform: "none" }} href="/" />
          {/* <Tab label="About Us" sx={{ textTransform: "none" }} />
          <Tab label="Area Guide" sx={{ textTransform: "none" }} /> */}
          <Tab label="FAQ" sx={{ textTransform: "none" }} href="/faq" />
          <Tab label="Contact" sx={{ textTransform: "none" }} href="/contact" />
        </Tabs>
        <div style={{ display: "flex" }}>
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
