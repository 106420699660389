import React from "react";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import "./styles.css";

const ApprovalStatusFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Filter ${column.Header}`}
    />
  );
};

const NSTable = ({ data = [], customColumns = [] }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile_number",
      },
      {
        Header: "Approval Status",
        accessor: "approval_status", // Make sure this matches the key in your data
        Filter: ApprovalStatusFilter, // Custom filter component for the approval_status column
      },
      ...customColumns,
    ],
    [customColumns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // Use filters plugin
    useGlobalFilter // Use global filter plugin
  );

  const { globalFilter } = state;

  return (
    <div>
      {/* Global Filter Input */}
      <input
        style={{ marginLeft: 20 }}
        type="text"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search anything ..."
      />

      {/* Table */}
      <table {...getTableProps()} className="table table-bordered">
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NSTable;
