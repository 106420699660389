import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import apiConfig from "./apiConfig";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
// import Header from "../components/Header";
import NSTable from "../components/NSTable";

const adminPageDescription =
  "All the registered photographers will be listed here. Admin needs to approve the photographer to make him/her active.";

const AdminPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const apiUrl = `${apiConfig.apiUrl}`;

  function formatPhotographerData(data) {
    if (!data) return [];
    return data.map((photographer) => {
      return {
        ...photographer,
        documents: [
          {
            key: "Adhar",
            value: photographer.adhar_link,
          },
          {
            key: "Pan",
            value: photographer.pan_link,
          },
          {
            key: "Photo",
            value: photographer.avatar_link,
          },
        ],
      };
    });
  }

  const fetchDataFromServer = async () => {
    try {
      const response = await fetch(apiUrl + "/get_all_photographers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("*****************" + data);
        setTableData(formatPhotographerData(data.photographers)); // Ensure data.photographers is an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const handleLogin = () => {
    setLoggedIn(true);
    navigate("/admin");
    fetchDataFromServer();
  };

  const handleApprove = async (row) => {
    // Assuming your data structure is an array of objects
    //const approvedItem = tableData[rowId];

    try {
      // Make a POST request to the server with the approved item
      const response = await fetch(
        apiUrl + "/update_photographer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            approval_status: "APPROVED",
            mobile_number: row.mobile_number,
          }),
        }
      );

      if (response.ok) {
        // Item successfully approved on the server, you may want to update UI or do other actions
        console.log(`Photographer Approved`);
        fetchDataFromServer();
      } else {
        console.error("Failed to approve item on the server");
      }
    } catch (error) {
      console.error("Error approving item on the server:", error.message);
    }
  };

  const handleReject = async (row) => {
    // Implement logic to reject the item with the given ID
    try {
      // Make a POST request to the server with the approved item
      const response = await fetch(
        apiUrl + "/update_photographer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            approval_status: "REJECTED",
            mobile_number: row.mobile_number,
          }),
        }
      );

      if (response.ok) {
        // Item successfully approved on the server, you may want to update UI or do other actions
        console.log(`Photographer Rejected`);
        fetchDataFromServer();
      } else {
        console.error("Failed to reject item on the server");
      }
    } catch (error) {
      console.error("Error rejecting item on the server:", error.message);
    }
  };

  if (loggedIn) {
    const pendingRows = tableData; //.filter((row) => row.approval_status === 'PENDING');
    return (
      <div
        style={{
          margin: "auto",
          width: "100%",
          padding: "10px",
        }}
      >
        {/* <Header pageName="Admin" description={adminPageDescription} /> */}
        <NSTable
          data={tableData}
          customColumns={[
            {
              Header: "Documents",
              accessor: "documents",
              Cell: ({ cell: { value } }) => (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {value?.map(({ key, value }, index) => (
                    <a href={value} target="_blank" rel="noopener noreferrer">
                      {key}
                    </a>
                  ))}
                </div>
              ),
            },
            {
              Header: "Actions",
              Cell: ({ row }) => {
                const isPending = row.original.approval_status === "PENDING";
                return isPending ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="btn btn-success"
                      onClick={() => handleApprove(row.original)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleReject(row.original)}
                    >
                      Reject
                    </button>
                  </div>
                ) : null;
              },
            },
          ]}
        />
      </div>
    );
  } else {
    return <LoginPage onLogin={handleLogin} />;
  }
};

export default AdminPage;
