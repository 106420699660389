import React from "react";
import PropTypes from "prop-types";
import "./ImageContent.css";

const ImageContent = ({ image, content, swap }) => {
  return (
    <div className={`image-container ${swap ? "swap" : ""}`}>
      <div className="image">
        <img src={image} alt="Mission" />
      </div>
      <div className="image-content">{content}</div>
    </div>
  );
};

ImageContent.propTypes = {
  image: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  swap: PropTypes.bool,
};

ImageContent.defaultProps = {
  swap: false,
};

export default ImageContent;
