import React from "react";
import "./PolicyPage.css";
import { Typography, List, ListItem, ListItemText } from "@mui/material";

const PolicyPage = () => {
  return (
    <div className="PolicyPage">
      <Typography variant="h4" gutterBottom textAlign="left">
        Privacy Policy
      </Typography>
      <Typography variant="body1" textAlign="left">
        {/* Effective Date: [Insert Date] */}
        Thank you for choosing NoSelfiee! At NoSelfiee, we are committed to
        protecting your privacy and ensuring the security of your personal
        information. This Privacy Policy outlines how we collect, use, disclose,
        and protect your information when you use our mobile application
        ("App").
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        1. Information We Collect
      </Typography>
      <Typography variant="body1" textAlign="left">
        When you use NoSelfiee, we may collect the following types of
        information:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Personal Information"
            secondary="This includes your name, email address, phone number, and payment details."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Booking Information"
            secondary="Information related to your bookings, such as photographer preferences, booking times, and locations."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Device Information"
            secondary="We may collect information about the device you use to access our App, including IP address, device type, and operating system."
          />
        </ListItem>
      </List>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" textAlign="left">
        We use the information we collect for the following purposes:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="To provide our services and fulfill your bookings." />
        </ListItem>
        <ListItem>
          <ListItemText primary="To communicate with you regarding your bookings, updates, and promotions." />
        </ListItem>
        <ListItem>
          <ListItemText primary="To improve our services, personalize your experience, and develop new features." />
        </ListItem>
        <ListItem>
          <ListItemText primary="To ensure the security and integrity of our App and protect against fraud." />
        </ListItem>
      </List>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        3. Sharing of Information
      </Typography>
      <Typography variant="body1" textAlign="left">
        We do not sell your personal information to third parties. However, we
        may share your information in the following circumstances:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="With photographers to fulfill your booking." />
        </ListItem>
        <ListItem>
          <ListItemText primary="With service providers who assist us in operating our App and providing our services." />
        </ListItem>
        <ListItem>
          <ListItemText primary="When required by law or to protect our rights and the rights of others." />
        </ListItem>
      </List>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        4. Data Security
      </Typography>
      <Typography variant="body1" textAlign="left">
        We take reasonable measures to protect your information from
        unauthorized access, use, or disclosure. However, please note that no
        method of transmission over the internet or electronic storage is 100%
        secure.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        5. Your Choices
      </Typography>
      <Typography variant="body1" textAlign="left">
        You can manage your information and communication preferences through
        the settings in the App. You can also choose not to provide certain
        information, but this may limit your ability to use some features of
        NoSelfiee.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        6. Changes to this Privacy Policy
      </Typography>
      <Typography variant="body1" textAlign="left">
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new policy on our App and updating the
        effective date above.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        7. Contact Us
      </Typography>
      <Typography variant="body1" textAlign="left">
        If you have any questions about this Privacy Policy or our practices,
        please contact us at [help@noselfiee.com].
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        8. Transparency
      </Typography>
      <Typography variant="body1" textAlign="left">
        NoSelfiee is open and honest about its prices. NoSelfiee ensures that
        clients understand peak time charge dynamics and how it operates.
        Customers receive explicit notification whenever a peak hour fee is
        applicable, confirming their intention to book the session at a higher
        cost than usual. The booking process only begins after they confirm, so
        customers have the option to opt out of that session, which frees up the
        mobile photographer to assist others in need.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        9. Cancellation Policy
      </Typography>
      <Typography variant="body1" textAlign="left">
        You can ask for a Cancellation Fee Waiver if you believe that the
        cancellation fee you were charged was applied erroneously. In order to
        reimburse mobile photographers for their time, gasoline, and effort in
        getting to the session location, a cancellation fee is applied. Any of
        the following could result in a cancellation fee being assessed to you:
        (a) a session is canceled three (3) minutes or less after the mobile
        photographer accepts the appointment, or (b) A mobile photographer
        cancels the appointment after arriving at the venue of your session for
        at least five minutes. However, if the mobile photographer is more than
        five minutes late for the session compared to the estimated arrival
        time, there won't be a cancellation fee. Depending on your city and the
        mobile photographer category you choose, the cancellation fee amount may
        change. Should there be a cancellation fee, it will be applied to the
        total cost of your subsequent NoSelfiee session.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        10. Offer Specific Terms & Conditions
      </Typography>
      <Typography variant="body1" textAlign="left">
        The Customer(s)/You/Your) and Vipas Innovative Technologies Private
        Limited ("NoSelfiee") will enter into a legally binding and enforceable
        contract based on these general terms and conditions of the offer, or
        "General Offer Terms." You are under no obligation to take advantage of
        the Offer. You hereby expressly agree to be bound by the following terms
        by taking advantage of the Offer. Participation is entirely voluntary,
        and using the NoSelfiee Platform to make a purchase or conduct any other
        transaction is considered acceptance of the terms and conditions listed
        below. The entire agreement between NoSelfiee and You is contained in
        these General Offer Terms and any additional offer-specific terms that
        NoSelfiee may from time to time issue. In the event of a dispute, these
        General Offer Terms will take precedence over any other offer-specific
        terms, unless those terms specifically state otherwise. The promotion
        can only be used on the NoSelfiee mobile application. It is not
        applicable to mobile photographer services. All claims against the third
        party ("Service Provider") offering the service to which the offer
        applies will be directed towards that third party. Third parties on the
        NoSelfiee mobile application ("NoSelfiee Platform") are responsible for
        the mobile-photographers services ("Services") they provide. It is
        against NoSelfiee's policy to combine Offers with other Offers. If you
        are prohibited from utilizing NoSelfiee's services during the Offer
        Period, you may lose your ability to participate in this Offer.
        NoSelfiee maintains the right to exclude any Customer from the
        Campaign/Offer benefits if it is determined that the Customer engaged in
        fraudulent activity by using the service to hire a mobile photographer
        in order to take advantage of the Campaign/Offer benefits or for other
        purposes.Including, without limitation, the implied warranties or
        conditions of merchantability, quality, and/or fitness for the goods or
        services included in this Offer, the Services, or for any other purpose,
        NoSelfiee disclaims all warranties, conditions, or statements, whether
        express, implied, or statutory.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        11. Limitations
      </Typography>
      <Typography variant="body1" textAlign="left">
        Images of products that are advertised on the NoSelfiee website or in
        correspondence sent to the customer are merely for illustration reasons
        and might not accurately depict the services that the relevant service
        provider actually offers. Under no circumstances will NoSelfiee be held
        accountable for the same. The services provided under this program are
        contingent upon their availability from the relevant service provider in
        the relevant geographic area. NoSelfiee shall be held responsible for
        any unavailability of services under any circumstances. The offer or
        discount made possible by this program will never be settled or
        exchanged for cash. Any losses arising from the usage of the services
        are solely the responsibility of the particular service provider.
        Regardless of whether it had prior knowledge of the possibility of any
        such damages, neither Selfiee nor the Service Provider shall be liable
        for any indirect, speculative, consequential, or punitive damages. This
        includes, but is not limited to, any loss of use, loss of data, or loss
        of income or profits. Regardless of the terms of this General Offer
        Terms, NoSelfiee and the Service Provider's total liability will never
        exceed Rs. 1000/- (Rupees One Thousand Only), regardless of the type of
        claim that gives rise to such liability or whether it is based on a
        contract, tort, or other legal theory. The Offer is subject to customary
        force majorities in more situations. NoSelfiee may, at any point during
        the Offer Period, at its sole discretion refuse, deny, cease, withdraw,
        and terminate the Offer without giving You any explanation or previous
        notice. All issues must be handled amicably through photography; if this
        isn't possible within thirty (30) days, the parties will mutually
        designate a single arbitrator to settle the matter. Bengaluru, India
        will serve as the arbitration's seat, and the proceedings would be
        conducted in compliance with the 1996 Arbitration Conciliation Act. With
        the aforementioned caveats, Indian law will govern these general offer
        terms as well as the offer-specific terms, and Hyderabad, Telangana
        courts would have exclusive jurisdiction.
      </Typography>

      <Typography
        variant="h5"
        gutterBottom
        textAlign="left"
        sx={{ marginTop: 3 }}
      >
        12. Disclaimer
      </Typography>
      <Typography variant="body1" textAlign="left">
        To the fullest extent permitted by law, the customer agrees and
        acknowledges that using the Mobile App/Website is at their own risk and
        that the Company disclaims all representations and warranties of any
        kind, whether express or implied, regarding the product's condition,
        suitability, quality, and fitness for any particular purpose. This
        website's/application's content is only intended for general
        informational purposes. Although we try to keep the information current
        and accurate, NoSelfiee provides it and makes no express or implied
        representations or warranties of any kind regarding the website's
        completeness, accuracy, reliability, suitability, availability, or the
        information, goods, services, or related graphics it contains for any
        purpose. You consequently absolutely assume all risk associated with any
        reliance you may have on such material. We disclaim all liability for
        any loss or harm resulting from the use of this website, including but
        not limited to indirect or consequential loss or damage, or any loss or
        damage at all resulting from the loss of data or profits. This website
        allows you to link to external websites that are not managed by
        NoSelfiee. The availability, nature, and content of those websites are
        beyond our control. Any links provided are not meant to be
        recommendations or endorsements of the content found within. We make
        every effort to maintain the Website and/or Application operational. On
        the other hand, NoSelfiee disclaims all liability and assumes no
        responsibility should the Website or Application become momentarily
        unavailable as a result of technical problems outside of our control.
      </Typography>
    </div>
  );
};

export default PolicyPage;
