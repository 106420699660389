// src/Footer.js
import React from "react";
import { Container, Box, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "./assets/x.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: "black", py: 3, color: "white" }}>
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "left",
              margin: "20px",
              gap: "10px",
            }}
          >
            <nav>
              <ul
                style={{
                  display: "flex",
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  color: "white",
                }}
              >
                <li style={{ marginRight: "20px" }}>
                  <Link href="/" color="inherit" underline="none">
                    Home
                  </Link>
                </li>
                <li style={{ marginRight: "20px" }}>
                  <Link href="/faq" color="inherit" underline="none">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link href="/policy" color="inherit" underline="none">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </nav>
            <Typography variant="body2" color="inherit" align="center">
              © 2024 no selfiee. All rights reserved.
            </Typography>
          </Box>
          <Box>
            <IconButton href="https://www.facebook.com" color="inherit">
              <FacebookIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton href="https://www.instagram.com" color="inherit">
              <InstagramIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton href="https://www.twitter.com" color="inherit">
              <img
                src={TwitterIcon}
                alt="Twitter"
                style={{ width: 24, height: 24, color: "white" }}
              />
            </IconButton>
            <IconButton href="https://www.linkedin.com" color="inherit">
              <LinkedInIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
