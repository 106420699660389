import { useForm } from "react-hook-form";
import { useState } from "react";
import "./ContactUs.css";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Stack,
  Card,
  CardContent,
  CardActions,
  Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const ContactUs = () => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const BASE_URL = "http://no-selfiee-2095828051.ap-south-1.elb.amazonaws.com";

  const onSubmit = async (data) => {
    const { name, email, phone, message } = data;

    try {
      const response = await axios.post(`${BASE_URL}/support_request`, {
        mobile_number: phone,
        name: name,
        email: email,
        message: message,
      });

      console.log("Server Response:", response.data);
      setSuccess(true); // Show success alert
      reset(); // Reset the form on successful submission
    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };

  return (
    <Container className="ContactUs">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom textAlign="center">
                Contact Us
              </Typography>
              {success && (
                <Alert severity="success" onClose={() => setSuccess(false)}>
                  Your message has been sent successfully!
                </Alert>
              )}
              <form
                id="contact-form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                        maxLength: {
                          value: 30,
                          message: "Please use 30 characters or less",
                        },
                      })}
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Email Address"
                      type="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter a valid email address",
                        },
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Phone Number"
                      type="tel"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter your phone number",
                        },
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Phone number must be 10 digits",
                        },
                      })}
                      error={!!errors.phone}
                      helperText={errors.phone ? errors.phone.message : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Message"
                      multiline
                      rows={4}
                      {...register("message", {
                        required: {
                          value: true,
                          message: "Please enter a message",
                        },
                      })}
                      error={!!errors.message}
                      helperText={errors.message ? errors.message.message : ""}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions>
              <Grid container justifyContent="center">
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon sx={{ color: "white" }} />}
                    form="contact-form"
                  >
                    Send
                  </Button>
                </Stack>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUs;
