// HomePage.js
import React from "react";
import "./HomePage.css";
import logo from "../static/logo_1.png";
import ImageCarousel from "../components/ImageCarousel/ImageCarousel";
import ImageContent from "../components/ImageContent/ImageContent";
import mission from "../assets/mission.jpg";
import whoWeAre from "../assets/who.jpg";
import clickingImg from "../assets/clicking.jpg";
const HomePage = () => {
  const content = (
    <div>
      <h2>Our mission</h2>
      <p>
        With our app, you can say goodbye to awkward selfies and hello to
        professional photo sessions on the go. Simply book a session with a
        nearby photographer, and they will come to you to capture stunning
        images with your mobile device. You can choose the location, style, and
        duration of the session, and our photographers will ensure that you have
        a fun and memorable experience.
      </p>
      <p>
        We believe in providing a convenient and affordable photography solution
        for everyone, you can find a photographer that fits your budget and
        style, making professional photography accessible to all.
      </p>
      <p>
        So whether you're a traveler, a local, or just someone who loves to
        capture life's beautiful moments, our mobile photography services app is
        here to make your photo sessions easy, enjoyable, and unforgettable.
        Download the app today and start capturing your moments with the help of
        our talented photographers.
      </p>
    </div>
  );

  const whoWeAreContent = (
    <div>
      <h2>Who we are?</h2>
      <p>
        Whether you're celebrating a milestone event, embarking on a new
        journey, or simply want to capture the beauty of everyday life, our team
        is here to make it happen. We pride ourselves on our ability to capture
        the essence of each moment, allowing you to relive and share your most
        precious memories for years to come. With a keen eye for detail and a
        deep understanding of the power of photography, we are committed to
        delivering images that exceed your expectations and truly capture the
        magic of every moment.
      </p>
    </div>
  );
  return (
    <div className="App">
      <header className="App-header ">
        {/* <img src={logo} alt="NoSelfiee Logo" className="App-logo" /> */}
        <ImageCarousel />
        <br />
        <div className="title">
          <p>
            Welcome to our mobile photography services app! We believe that
            every moment is worth capturing, and that's why we have created a
            platform where you can easily connect with nearby photographers to
            capture your special moments.
          </p>
        </div>
      </header>
      <ImageContent image={whoWeAre} content={whoWeAreContent} swap={true} />
      <br />

      <div className="clickImage">
        <img src={clickingImg} alt="clickingImg" />
      </div>
      <br />

      <ImageContent image={mission} content={content} swap={false} />
    </div>
  );
};

export default HomePage;
