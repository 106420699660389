// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import apiConfig from './apiConfig';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const apiUrl = `${apiConfig.apiUrl}`;

  const handleLogin = async () => {
    try {
      onLogin()
      // const response = await fetch(apiUrl+'/admin_login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ "username":username, "password":password }),
      // });

      // if (response.ok) {
      //   // Successfully authenticated
      //   response.json().then((data) => {
      //       localStorage.setItem('token', data.token);
      //       onLogin();
      //   });

      // } else {
      //   // Failed authentication
      //   alert('Invalid username or password');
      // }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error during login:', error.message);
      alert('Error during login. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="https://i.imgur.com/5vzKDmY.png" alt="Company Logo" className="company-logo" />
        <h1 className="company-name">Admin Login</h1>
        <label className="login-label">
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label className="login-label">
          Password   :
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
